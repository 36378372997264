import React, { useState } from 'react'

import {
  Button,
  Box,
  Card,
  Divider,
  Input,
  CircularProgress,
  Typography,
  Container,
} from '@mui/joy'

import { GoogleLogin } from '@react-oauth/google'
import { useGoogleLogin } from '@react-oauth/google'

const NewGoogleMailbox = () => {
  const [loading, setLoading] = useState(false)

  const login = useGoogleLogin({
    flow: 'initCodeClient',
    onSuccess: (tokenResponse) => {
      const signIn = async () => {
        setLoading(true)

        const res = await fetch('/sign-up-with-gmail', {
          method: 'post',
          body: JSON.stringify(tokenResponse),
        })
        const info = await res.json()

        setLoading(false)

        console.log(info)

        window.location.reload()
      }

      signIn()
    },
    scope: 'profile email https://mail.google.com',
  })

  return (
    <Button disabled={loading} onClick={() => login()}>
      {loading ? 'Loading...' : 'Sign Up with Google'}
    </Button>
  )
}

const NewMailbox = () => {
  const [host, setHost] = useState('')
  const [user, setUser] = useState('')
  const [pass, setPass] = useState('')
  const [open, setOpen] = useState(false)

  const [loading, setLoading] = useState()

  if (!open) {
    return (
      <Button disabled onClick={() => setOpen(true)}>
        Sign Up with IMAP
      </Button>
    )
  }

  return (
    <Card>
      <Typography>New mailbox (IMAP -- advanced).</Typography>
      <Input
        placeholder="hostname"
        value={host}
        onChange={(e) => setHost(e.target.value)}
      />
      <Input
        placeholder="username"
        value={user}
        onChange={(e) => setUser(e.target.value)}
      />
      <Input
        type="password"
        placeholder="password"
        value={pass}
        onChange={(e) => setPass(e.target.value)}
      />
      <Button
        disabled={loading || !(host && user && pass)}
        onClick={() => {
          //setLoading(true);

          const fn = async () => {
            const req = await fetch('/new-account', {
              method: 'post',
              body: JSON.stringify({
                host,
                user,
                pass,
              }),
            })
            const res = await req.json()

            console.log('RES')
          }

          fn()
        }}
      >
        Submit
      </Button>
    </Card>
  )
}

export const LandingPage = () => (
  <Container>
    <Card sx={{ mb: 2, mt: 30 }}>
      <NewGoogleMailbox />
    </Card>

    <Card sx={{ mb: 2 }}>
      <Typography color="danger">
        envelo.ps is currently in closed beta.
      </Typography>

      <Typography>
        package, seal, and send your most precious correspondences.
      </Typography>

      <Typography component="ul">
        <li>encrypted redactions with secure review process</li>
        <li>
          mechanical filing capabilities for incoming mail and web site updates
        </li>
        <li>
          portable .ps file format supporting email, epub, html, and pdf
          converters
        </li>
        <li>
          dataset curation with API for programmatic access and AI training
        </li>
      </Typography>
    </Card>

    <Card sx={{ mb: 2 }}>
      <Typography>privacy policy & terms of service</Typography>

      <Typography component="ul">
        <li>we will scan your inbox daily to sync new messages</li>
        <li>
          we will store all of your messages on our Google Cloud Platform
          instance
        </li>
        <li>we will not use any other third-party data processors</li>
        <li>we will not sell or share your data with any third party</li>
      </Typography>

      <Typography component="ul">
        <li>
          you can make envelopes with filters to receive emails, past and future
        </li>
        <li>you can also add webpages, epubs, and pdfs to your envelopes</li>
        <li>
          you can edit, highlight, cut, and redact anything in an envelope
        </li>
        <li>you can send an envelope to people and vet each other’s changes</li>
      </Typography>
    </Card>
  </Container>
)

export default LandingPage
