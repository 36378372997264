import React, { useEffect, useState } from 'react'

import { CircularProgress, Typography, Container } from '@mui/joy'

import LandingPage from './LandingPage'

export const DataProvider = React.createContext({
  db: null,
  whoami: null,
})

export const DataLoader = ({ children }) => {
  const [db, setDb] = useState(false)
  const [error, setError] = useState(false)

  const fetchDb = async () => {
    try {
      const ret = await fetch('/api/db')
      const obj = await ret.json()
      window.db = obj
      setDb(obj)
    } catch {
      // Not signed in! Do we have tokens?
      if (window.location.hash.length > 1) {
        // Launch in BG
        await checkForTokens()
      } else {
        setError(true)
        //throw new Error("Failed to fetch DB & no token provided");
      }
    }
  }

  useEffect(() => {
    const checkForTokens = async () => {
      // Looks at hash URL for a sign in token; attempts to sign in if found
      const token = window.location.hash.slice(1)

      if (token && !db) {
        window.location.hash = ''
        setError(false)

        try {
          const res = await fetch('/sign-in', {
            method: 'post',
            body: JSON.stringify({ token }),
          })
          await res.json()
          fetchDb()
        } catch {
          setError(true)
          //throw new Error("Invalid token");
        }
      }
    }

    window.onhashchange = () => checkForTokens()
    if (!db) {
      fetchDb()
    }
  }, [db])

  if (error) {
    return <LandingPage />
  } else if (!db) {
    return <CircularProgress />
  }

  return (
    <DataProvider.Provider value={{ fetchDb, ...db }}>
      {children}
    </DataProvider.Provider>
  )
}
