import React, { useContext, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import CssBaseline from '@mui/joy/CssBaseline'

import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  Dropdown,
  Input,
  IconButton,
  Textarea,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Menu,
  MenuItem,
  MenuButton,
  Modal,
  ModalClose,
  Sheet,
  Typography,
} from '@mui/joy'

import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import Delete from '@mui/icons-material/Delete'

import { CssVarsProvider, extendTheme } from '@mui/joy/styles'

import { DataProvider, DataLoader } from './Data'

import { GoogleOAuthProvider } from '@react-oauth/google'

import * as EmailValidator from 'email-validator'

import '@fontsource-variable/cabin'

const theme = extendTheme({
  fontFamily: {
    display: "'Cabin Variable', sans-serif", // applies to `h1`–`h4`
    body: "'Cabin Variable', sans-serif", // applies to `title-*` and `body-*`
  },
  colorSchemes: {
    light: {
      palette: {},
    },
    dark: {
      palette: {},
    },
  },
})

const Footer = () => {
  const { db, whoami } = useContext(DataProvider)

  return (
    <>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Avatar variant="outlined" size="sm" />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{whoami}</Typography>
          <Typography level="body-xs"></Typography>
        </Box>
        <IconButton
          size="sm"
          variant="plain"
          color="neutral"
          onClick={() => {
            const fn = async () => {
              const res = await fetch('/api/sign-out')
              await res.json()
              console.log(res)
              window.location.reload()
            }
            fn()
          }}
        >
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
    </>
  )
}

const EnvelopeList = ({ openEnvs, setOpenEnvs }) => {
  const [open, setOpen] = useState(false)

  const { db } = useContext(DataProvider)

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          sx={{ maxWidth: 500, p: 3, boxShadow: 'lg', borderRadius: 'md' }}
        >
          <NewEnvelope onClose={() => setOpen(false)} />
        </Sheet>
      </Modal>
      <div>
        <Button onClick={() => setOpen(true)}>New Envelope</Button>

        <List>
          {Object.entries(db.envelope || {}).map(([envId, env]) => (
            <ListItem>
              <Checkbox
                checked={openEnvs[envId]}
                onChange={(ev) => {
                  setOpenEnvs({ ...openEnvs, [envId]: ev.target.checked })
                }}
                label={Object.keys(env.filter.email || {})[0]}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </>
  )
}

const NewEnvelope = ({ onClose }) => {
  const { whoami, fetchDb } = useContext(DataProvider)

  const [emailWith, setEmailWith] = useState('')
  const [filterPreview, setFilterPreview] = useState()

  const [creating, setCreating] = useState()

  const validEmail = EmailValidator.validate(emailWith)

  useEffect(() => {
    if (validEmail) {
      const go = { ok: true }

      const fn = async () => {
        const res = await fetch('/api/gmail-preview', {
          method: 'post',
          body: JSON.stringify({
            q: `from:${emailWith} OR to:${emailWith}`,
          }),
        })

        if (go.ok) {
          const out = await res.json()
          setFilterPreview(out)
        }
      }
      fn()

      return () => {
        go.ok = false
      }
    } else {
      setFilterPreview()
    }
  }, [emailWith, validEmail])

  return (
    <Box>
      <Typography>Create a new envelope</Typography>

      <Card sx={{ mb: 2, mt: 2 }}>
        <Input
          error={emailWith && !validEmail}
          value={emailWith}
          placeholder="Email address"
          onChange={(e) => setEmailWith(e.target.value)}
        />
        {validEmail ? (
          <Typography>
            All emails in <b>{whoami}</b> with a sender or recipient matching{' '}
            <b>{emailWith}</b> will be added to this envelope{' '}
            <Typography color="danger">
              and removed from the <b>{whoami}</b> mailbox.
            </Typography>
          </Typography>
        ) : (
          ''
        )}

        {validEmail ? (
          filterPreview ? (
            <List>
              {filterPreview.snippets.map(({ id, snippet }) => (
                <ListItem key={id}>
                  <Typography level="body-sm" noWrap>
                    {snippet}
                  </Typography>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography>Loading preview...</Typography>
          )
        ) : (
          ''
        )}
      </Card>

      <Button
        onClick={() => {
          setCreating(true)
          const fn = async () => {
            await fetch('/api/new-envelope', {
              method: 'post',
              body: JSON.stringify({
                email: emailWith,
              }),
            })

            setCreating(false)

            await fetchDb()

            onClose()
          }
          fn()
        }}
        loading={creating}
        disabled={!validEmail}
      >
        Create Envelope
      </Button>
    </Box>
  )
}

const EnvelopeViewer = ({ openEnvs }) => {
  const [envs, setEnvs] = useState({})

  useEffect(() => {
    // Load envelopes as they're checked...

    const fn = async () => {
      const newEnvs = { ...envs }
      let change = false

      for (const [envId, toggledOn] of Object.entries(openEnvs)) {
        if (!toggledOn) {
          continue
        }
        if (!(envId in envs)) {
          const res = await fetch('/api/fetch-envelope', {
            method: 'post',
            body: JSON.stringify({ id: envId }),
          })
          const env = await res.json()

          newEnvs[envId] = env
          change = true
        }
      }

      if (change) {
        setEnvs(newEnvs)
      }
    }
    fn()
  }, [envs, openEnvs])

  const body = useMemo(() => {
    const parts = []

    const allEnvs = Object.entries(openEnvs)
      .filter((e) => e[1])
      .map((e) => envs[e[0]])
    if (allEnvs.filter((x) => !x).length) {
      // Something is still loading...
      return parts
    }

    for (const env of allEnvs) {
      for (const [msgId, { date, payload }] of Object.entries(env)) {
        parts.push({ date, payload })
      }
    }

    parts.sort((x, y) => (x.date > y.date ? 1 : -1))

    return parts
  }, [envs, openEnvs])

  const envCnt = Object.values(openEnvs).filter((x) => x).length

  if (!envCnt) {
    return <Typography>No envelopes are open</Typography>
  }

  if (!body) {
    return <Typography>Loading...</Typography>
  }

  return (
    <Sheet>
      {body.map(({ date, payload }) => {
        console.log('DATE', date, typeof date)

        return (
          <Card>
            <div>{new Date(Number(date)).toString()}</div>
            {payload.split('\r\n').map((x) => (
              <Box sx={{ p: 0, m: 0 }}>{x}</Box>
            ))}
          </Card>
        )
      })}
    </Sheet>
  )
}

const Main = ({ children }) => {
  const [openEnvs, setOpenEnvs] = useState({})

  return (
    <Container>
      <Box sx={{ display: 'flex', minHeight: 'calc(100dvh - 40px)' }}>
        <EnvelopeList openEnvs={openEnvs} setOpenEnvs={setOpenEnvs} />

        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: '12px',
              sm: '12px',
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            minHeight: 'calc(100dvh - 40px)',
            gap: 1,
          }}
        >
          <EnvelopeViewer openEnvs={openEnvs} />
        </Box>
      </Box>
      <Footer />
    </Container>
  )
}

const OAUTH_CLIENT_ID =
  window.location.host === 'envelo.ps'
    ? '395582500294-l1amcouv4h6ega2tmn005j3kukfjpotc.apps.googleusercontent.com'
    : '630806176702-e9eivmqtkene08k3n1po8eqfemqhdftv.apps.googleusercontent.com'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <CssVarsProvider
      defaultMode="system"
      theme={theme}
      modeStorageKey="envelops-system-mode"
      disableNestedContext
    >
      <CssBaseline />
      <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
        <DataLoader>
          <Main />
        </DataLoader>
      </GoogleOAuthProvider>
    </CssVarsProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
